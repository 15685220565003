/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Collapse, Fade, Grid, Paper } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import get from 'lodash/get'
import { SortEnd } from 'react-sortable-hoc'

import { useDispatch, useSelector } from 'react-redux'
import { ShipmentTour } from 'common/models/typings/Tour'
import { isValidCollation } from 'actions/tours/helpers'
import { changeCollationPosition } from 'actions/tours'
import { getLastStopTime, isPastDeadLine } from 'utils'

import Typography from 'common/components/base/Typography'
import Icon from 'common/components/base/Icon'
import Callout from 'common/components/base/.raw/Callout'
import AutoComplete from './AutoComplete'
import Actions from './Actions'
import SortableItem from '../TourPlanning/Tour/SortableItem'

import useStyles from './useStyles'
import { NETWORK_TYPE } from '../../../models/typings/TourOverview'
import * as fromNetworkType from '../../../../selectors/networkType'
import { calculateTotalHoursOfTour } from './calculateTotalHoursOfTour'

import * as fromGreenRegions from '../../../../selectors/greenRegions'

export interface TourCardProps {
  id: ShipmentTour['id']
  color: ShipmentTour['color']
  collations: ShipmentTour['collations']
  hasBermudaStop: ShipmentTour['hasBermudaStop']
  number: ShipmentTour['number']
  totalDistance: ShipmentTour['totalDistance']
  isWorking: ShipmentTour['isWorking']
  driverId: ShipmentTour['driverId']
  vehicleType: ShipmentTour['vehicleType']
  totalDuration: ShipmentTour['totalDuration']
  key?: string | number
}

const TourCard: React.FC<TourCardProps> = ({
  id,
  color,
  collations,
  hasBermudaStop,
  number,
  totalDistance,
  totalDuration,
  isWorking,
  driverId,
  vehicleType,
  key,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const networkType: NETWORK_TYPE = useSelector(fromNetworkType.getNetworkType)

  const greenRegions = useSelector(fromGreenRegions.getGreenRegions) as {
    [key: string]: { id: number; pc3: number; polygon: any }
  }

  const selectedTourId: string | null = useSelector(
    (state: any) => state.tours.selectedTourId,
  )

  const isTourSelected = React.useMemo(
    // eslint-disable-next-line eqeqeq
    () => id == selectedTourId,
    [id, selectedTourId],
  )

  const { startTime, endTime, pastDeadline, totalShipments } =
    React.useMemo(() => {
      return {
        startTime: moment(get(collations, '[0].estimation')).format('HH:mm'),
        endTime: moment(getLastStopTime(collations)).format('HH:mm'),
        pastDeadline: isPastDeadLine(collations, networkType),
        totalShipments: collations
          .filter(isValidCollation as any)
          .reduce((a: number, b) => a + b.shipmentIds.length || a, 0),
      }
    }, [collations])

  const totalDistanceInKM = React.useMemo(
    () => (totalDistance / 1000).toFixed(1).concat(' km'),
    [totalDistance],
  )

  const getContainer: any = () =>
    document.getElementById(`collapse-container-${id}`)

  const handleChangeCollationPosition = (sort: SortEnd) => {
    dispatch(changeCollationPosition(id, sort.oldIndex, sort.newIndex))
  }

  const amountOfStops = collations.length - 2

  const totalHoursOfTour = calculateTotalHoursOfTour(startTime, endTime)

  const productivity = React.useMemo(() => {
    return (amountOfStops / totalHoursOfTour).toFixed(1)
  }, [amountOfStops, totalHoursOfTour, handleChangeCollationPosition])

  const isWithinGreenRegion = React.useMemo(() => {
    if (greenRegions && collations) {
      const pc3s = Object.values(greenRegions).map((region) =>
        String(region.pc3),
      )
      return collations.some((collation) =>
        pc3s.includes(
          get(collation, 'recipient.location.postalCode', '').substring(0, 3),
        ),
      )
    }
    return false
  }, [greenRegions, collations])

  return (
    <React.Fragment key={key}>
      <Paper
        elevation={1}
        className={clsx(classes.paper, {
          [classes.borderDanger]: pastDeadline,
        })}
      >
        <Grid container spacing={3}>
          <Box className={classes.tourLabelContainer}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box className={classes.colorIndicator} bgcolor={color} />
              <Typography
                className={classes.label}
              >{`Tour ${number}`}</Typography>
            </div>
            <Typography className={classes.productivity}>
              {t('TOUR_PRODUCTIVITY', {
                count: productivity as unknown as number,
              })}
            </Typography>
            <Fade in={hasBermudaStop && selectedTourId !== id}>
              <Box marginLeft={1} height={24}>
                <Icon name="danger" fill="#FF5351" />
              </Box>
            </Fade>
          </Box>
          <Grid item xs={12} container justifyContent="flex-end">
            <Grid item xs={6}>
              <Actions id={id} color={color} hasBermudaStop={hasBermudaStop} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <Box className={classes.labelContainer}>
                  <Box className={classes.perLabelContainer}>
                    <Typography noWrap component="span" variant="packageText">
                      {t('DEPART')}
                    </Typography>
                    <Typography noWrap className={classes.label}>
                      {startTime}
                    </Typography>
                  </Box>
                  <Box className={classes.perLabelContainer}>
                    <Typography noWrap component="span" variant="packageText">
                      {t('LAST_STOP')}
                    </Typography>
                    <Typography
                      noWrap
                      className={clsx(classes.label, {
                        [classes.labelRed]: pastDeadline,
                      })}
                    >
                      {endTime}
                    </Typography>
                  </Box>
                  <Box className={classes.perLabelContainer}>
                    <Typography noWrap component="span" variant="packageText">
                      {t('SHIPMENTS')}
                    </Typography>
                    <Typography noWrap className={classes.label}>
                      {totalShipments}
                    </Typography>
                  </Box>
                  <Box className={classes.perLabelContainer}>
                    <Typography noWrap component="span" variant="packageText">
                      {t('TOTAL_DISTANCE')}
                    </Typography>
                    <Typography noWrap className={classes.label}>
                      {totalDistanceInKM}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box position="relative">
                  <AutoComplete
                    id={id}
                    value={driverId}
                    vehicleType={vehicleType}
                    isWorking={isWorking}
                    isGreenRegion={isWithinGreenRegion}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={isTourSelected} unmountOnExit>
          <div id={`collapse-container-${id}`}>
            {hasBermudaStop && (
              <Box marginTop={2}>
                <Callout variant="danger">{t('BERMUDA_TEXT_CALLOUT')}</Callout>
              </Box>
            )}
            {/* <Reorder id={id} collations={collations} /> */}
            <SortableItem
              useDragHandle
              lockToContainerEdges
              helperClass="drag-helper"
              lockAxis="y"
              useWindowAsScrollContainer={false}
              getContainer={getContainer}
              key={id}
              id={id}
              collations={collations}
              onSortEnd={handleChangeCollationPosition}
            />
          </div>
        </Collapse>
      </Paper>
    </React.Fragment>
  )
}

export default TourCard
